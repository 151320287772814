import React from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';

export default function AboutContentCard({ section, imagePosition = 'left' }) {
  const hasImages = section.images && section.images.length > 0;

  return (
    <Row className='my-4 align-items-stretch'>
      {hasImages ? (
        imagePosition === 'left' ? (
          <>
            <Col
              md={4}
              className='d-flex flex-wrap justify-content-center align-items-start'
            >
              {section.images.map((img, idx) => (
                <Card
                  key={idx}
                  className='mb-3 me-2'
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '120px',
                    flex: '1 1 auto',
                  }}
                >
                  <Image
                    src={img.url}
                    alt={`${section.title} ${idx + 1}`}
                    style={{
                      maxHeight: '200px',
                      objectFit: 'cover',
                      padding: '5px',
                    }}
                  />
                  {img.name && (
                    <Card.Body style={{ padding: '5px' }}>
                      <Card.Text style={{ fontSize: '12px' }}>
                        {img.name}
                      </Card.Text>
                    </Card.Body>
                  )}
                </Card>
              ))}
            </Col>
            <Col md={8} className='d-flex'>
              <Card className='w-100' style={{ height: '100%' }}>
                <Card.Body className='d-flex flex-column'>
                  <Card.Title
                    style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                  >
                    {section.title}
                  </Card.Title>
                  {section.paragraphs.map((paragraph, index) => (
                    <Card.Text key={index} style={{ marginBottom: '10px' }}>
                      {paragraph}
                    </Card.Text>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col md={8} className='d-flex'>
              <Card className='w-100' style={{ height: '100%' }}>
                <Card.Body className='d-flex flex-column'>
                  <Card.Title
                    style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                  >
                    {section.title}
                  </Card.Title>
                  {section.paragraphs.map((paragraph, index) => (
                    <Card.Text key={index} style={{ marginBottom: '10px' }}>
                      {paragraph}
                    </Card.Text>
                  ))}
                </Card.Body>
              </Card>
            </Col>
            <Col
              md={4}
              className='d-flex flex-wrap justify-content-center align-items-start'
            >
              {section.images.map((img, idx) => (
                <Card
                  key={idx}
                  className='mb-3 me-2'
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '120px',
                    flex: '1 1 auto',
                  }}
                >
                  <Image
                    src={img.url}
                    alt={`${section.title} ${idx + 1}`}
                    style={{
                      maxHeight: '200px',
                      objectFit: 'cover',
                      padding: '5px',
                    }}
                  />
                  {img.name && (
                    <Card.Body style={{ padding: '5px' }}>
                      <Card.Text style={{ fontSize: '12px' }}>
                        {img.name}
                      </Card.Text>
                    </Card.Body>
                  )}
                </Card>
              ))}
            </Col>
          </>
        )
      ) : (
        <Col md={12}>
          <Card>
            <Card.Body>
              <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {section.title}
              </Card.Title>
              {section.paragraphs.map((paragraph, index) => (
                <Card.Text key={index} style={{ marginBottom: '10px' }}>
                  {paragraph}
                </Card.Text>
              ))}
            </Card.Body>
          </Card>
        </Col>
      )}
    </Row>
  );
}
