import React, { useEffect, useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DesignScreenContentEdit() {
  const [content, setContent] = useState([]);
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axios.get('/api/design', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setContent(data.sections || []); // Ensure data.sections is an array
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch content');
      }
    };
    fetchContent();
  }, [userInfo]);

  const handleTitleChange = (sectionIndex, e) => {
    const newContent = [...content];
    newContent[sectionIndex].title = e.target.value;
    setContent(newContent);
  };

  const handleParagraphChange = (sectionIndex, paragraphIndex, e) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs[paragraphIndex] = e.target.value;
    setContent(newContent);
  };

  const handleAddParagraph = (sectionIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs.push('');
    setContent(newContent);
  };

  const handleDeleteParagraph = (sectionIndex, paragraphIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs.splice(paragraphIndex, 1);
    setContent(newContent);
  };

  const handleAddSection = () => {
    setContent([...content, { title: '', paragraphs: [''], images: [] }]);
  };

  const handleDeleteSection = (sectionIndex) => {
    const newContent = [...content];
    newContent.splice(sectionIndex, 1);
    setContent(newContent);
  };

  // Handle image uploads for each section
  const handleImageUpload = async (sectionIndex, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      const imageName = prompt('Enter a name for the image (optional):'); // Prompt for a name
      const newContent = [...content];
      newContent[sectionIndex].images.push({
        url: data,
        name: imageName || '',
      });
      setContent(newContent);
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to upload image');
    }
  };

  const handleFileChange = (sectionIndex, e) => {
    const file = e.target.files[0];
    if (file) {
      handleImageUpload(sectionIndex, file); // Upload the image when file changes
    }
  };

  const handleDeleteImage = (sectionIndex, imageIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].images.splice(imageIndex, 1);
    setContent(newContent);
  };

  const handleImageNameChange = (sectionIndex, imageIndex, e) => {
    const newContent = [...content];
    newContent[sectionIndex].images[imageIndex].name = e.target.value;
    setContent(newContent);
  };

  const handleSectionSubmit = async (sectionIndex) => {
    try {
      const section = content[sectionIndex];
      await axios.put(
        `/api/design/section/${sectionIndex}`, // Make sure this path matches the backend route
        { section },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Section updated successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to update section');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        '/api/design',
        { sections: content },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setContent(data.sections); // Update the content with the response data
      toast.success('Content updated successfully', { autoClose: 1000 });
    } catch (error) {
      console.error(error);
      toast.error('Failed to update content', { autoClose: 1000 });
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>Design Edit</title>
      </Helmet>
      <br />
      <h1 className='box'>Design Edit</h1>
      <Form onSubmit={handleSubmit}>
        {content.map((section, sectionIndex) => (
          <div key={sectionIndex} className='mb-4'>
            <Form.Group controlId={`formTitle${sectionIndex}`}>
              <Form.Label>Title {sectionIndex + 1}</Form.Label>
              <Form.Control
                type='text'
                value={section.title}
                onChange={(e) => handleTitleChange(sectionIndex, e)}
              />
            </Form.Group>

            {section.paragraphs.map((paragraph, paragraphIndex) => (
              <Form.Group
                controlId={`formParagraph${sectionIndex}-${paragraphIndex}`}
                key={paragraphIndex}
              >
                <Form.Label>Paragraph {paragraphIndex + 1}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  value={paragraph}
                  onChange={(e) =>
                    handleParagraphChange(sectionIndex, paragraphIndex, e)
                  }
                />
                <Button
                  variant='danger'
                  onClick={() =>
                    handleDeleteParagraph(sectionIndex, paragraphIndex)
                  }
                  className='mt-2'
                >
                  Delete Paragraph
                </Button>
              </Form.Group>
            ))}

            <Button
              onClick={() => handleAddParagraph(sectionIndex)}
              className='mt-2 mr-2'
            >
              Add Paragraph
            </Button>

            {/* Image Upload Field */}
            <Form.Group controlId={`formImageUpload${sectionIndex}`}>
              <Form.Label>Upload Images</Form.Label>
              <Form.Control
                type='file'
                accept='image/*'
                onChange={(e) => handleFileChange(sectionIndex, e)}
              />
              <div className='mt-2'>
                {section.images &&
                  section.images.map((image, imageIndex) => (
                    <div key={imageIndex} className='mt-2'>
                      <img
                        src={image.url} // Accessing the URL property correctly
                        alt={`Preview ${imageIndex + 1}`}
                        style={{ maxWidth: '100px', height: 'auto' }}
                      />
                      <Form.Control
                        type='text'
                        placeholder='Enter image name'
                        value={image.name || ''}
                        onChange={(e) =>
                          handleImageNameChange(sectionIndex, imageIndex, e)
                        }
                        className='mt-2'
                      />
                      <Button
                        variant='danger'
                        onClick={() =>
                          handleDeleteImage(sectionIndex, imageIndex)
                        }
                        size='sm'
                        className='mt-2'
                      >
                        Delete Image
                      </Button>
                    </div>
                  ))}
              </div>
              <Button
                variant='primary'
                onClick={() => handleSectionSubmit(sectionIndex)}
                className='mt-2'
              >
                Save Changes for This Section
              </Button>
              &nbsp;
              <Button
                variant='danger'
                onClick={() => handleDeleteSection(sectionIndex)}
                className='mt-2'
              >
                Delete Section
              </Button>
            </Form.Group>
          </div>
        ))}
        <Button
          variant='success'
          onClick={handleAddSection}
          className='mt-2 mr-2'
        >
          Add Section
        </Button>
        &nbsp;
        <Button variant='primary' type='submit' className='mt-2'>
          Save Changes
        </Button>
      </Form>
      <br />
    </div>
  );
}
