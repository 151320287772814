import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div>
        <Row>
          <Col md={3}>
            Stay in touch
            <div className='socialIcon'>
              <ul className='list-unstyled'>
                <li>
                  <a
                    href='https://www.facebook.com/Exotic-Wood-Pen-460928980709091'
                    className='facebookIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Facebook "Exotic Wood Pen"
                  </a>
                </li>

                <li>
                  <a
                    href='https://www.facebook.com/profile.php?id=100045945043682'
                    className='facebookIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Facebook "Gilded Age Pens"
                  </a>
                </li>

                <li>
                  <a
                    href='https://www.youtube.com/channel/UCVdwaT1LF4iv6q5okes29tA'
                    className='youtubeIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    YouTube
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col md={3}>
            Get To Know Us
            <div className='socialIcon'>
              <ul className='list-unstyled'>
                <li>
                  <Link to='/about' className='email'>
                    {' '}
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to='/design' className='email'>
                    {' '}
                    Design Choices
                  </Link>
                </li>
                <li>
                  <Link to='/jig' className='email'>
                    {' '}
                    Published Popular Woodworking Jigs
                  </Link>
                </li>
                <li>
                  <Link to='/gallery' className='email'>
                    {' '}
                    Pen Gallery
                  </Link>
                </li>
                <li>
                  <Link to='/askedQuestions' className='email'>
                    {' '}
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          <Col md={3}>
            Pen Segmenting on Udemy
            <div className='socialIcon'>
              <ul className='list-unstyled'>
                <li>
                  <a
                    href='https://www.udemy.com/course/pen-segmenting-tools-and-jigs-part-1'
                    className='youtubeIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Part 1
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.udemy.com/course/part-2-pen-segmenting-with-basic-tools-and-jigs'
                    className='youtubeIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Part 2
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.udemy.com/course/part-3-pen-segmenting-with-basic-tools-and-jigs'
                    className='youtubeIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Part 3
                  </a>
                </li>
              </ul>
            </div>
            Learn to Code on Udemy
            <div className='socialIcon'>
              <ul className='list-unstyled'>
                <li>
                  <a
                    href='https://www.udemy.com/course/mern-stack-es6-e-commerce-from-beginning-to-deployment/?referralCode=F0A95F78FF4B98A11FE0'
                    className='youtubeIcon'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Mern Stack e-Commerce
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col md={3}>
            Pen Questions
            <div className='socialIcon'>
              <ul className='list-unstyled'>
                <Link to='/contact' className='email'>
                  {' '}
                  Contact Us
                </Link>
                <li>
                  <a href='mailto:exoticwoodpen@gmail.com' className='email'>
                    Email Me via gmail
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <hr className='hrLine' />

      <div>
        <Row>
          <Col className='text-center mt-3'>
            &copy;{new Date().getFullYear()} ExoticWoodPen
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
