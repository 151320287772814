import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DesignContentCard from '../components/DesignContentCard';
import { Helmet } from 'react-helmet-async';

export default function DesignScreen() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axios.get('/api/design'); // Fetch from your backend
        setContent(data.sections); // Assuming data contains "sections"
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  return (
    <div className='content'>
      <Helmet>
        <title>Design</title>
      </Helmet>
      <br />
      {content.length > 0 ? (
        content.map((section, index) => (
          <DesignContentCard
            key={index}
            section={section}
            imagePosition={index % 2 === 0 ? 'left' : 'right'} // Alternate image positions
          />
        ))
      ) : (
        <p>Loading content...</p>
      )}
      <br />
    </div>
  );
}
