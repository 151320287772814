import Axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';

export default function SignupScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [showEmailConfirmModal, setShowEmailConfirmModal] = useState(false); // State for email confirm modal
  const [loading, setLoading] = useState(false);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const { data } = await Axios.post('/api/users/signup', {
        name,
        email,
        password,
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate(redirect || '/');
    } catch (err) {
      toast.error(getError(err));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Function to handle email field blur event
  const handleEmailBlur = () => {
    if (!emailConfirmed && email) {
      setShowEmailConfirmModal(true); // Show the custom modal
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <br />
      <Row>
        <Col md={6}>
          <h1 className='box'>Sign Up</h1>
          <div className='box'>
            <Form onSubmit={submitHandler}>
              <Form.Group className='mb-3' controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='email'>
                <Form.Label>Email</Form.Label>
                <Form.Text muted>
                  Please double-check for spelling errors and ensure your email
                  is correct; otherwise, communication like receipts and
                  shipping notifications will not work.
                </Form.Text>
                <Form.Control
                  type='email'
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleEmailBlur}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Text muted>
                  Password must be at least 8 characters long and contain an
                  uppercase letter, a lowercase letter, a digit, and a special
                  character.
                </Form.Text>
                <div className='input-group'>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    variant='outline-secondary'
                    onClick={togglePasswordVisibility}
                  >
                    <i
                      className={`fa ${
                        showPassword ? 'fas fa-eye-slash' : 'fa-eye'
                      }`}
                    ></i>
                  </Button>
                </div>
              </Form.Group>

              <Form.Group className='mb-3' controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <div className='input-group'>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Re-enter your password'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <Button
                    variant='outline-secondary'
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <i
                      className={`fa ${
                        showConfirmPassword ? 'fas fa-eye-slash' : 'fa-eye'
                      }`}
                    ></i>
                  </Button>
                </div>
              </Form.Group>

              <div className='mb-3'>
                <Button type='submit' disabled={loading || !emailConfirmed}>
                  {loading ? 'Signing Up...' : 'Sign Up'}
                </Button>
              </div>
              <div className='mb-3'>
                Already have an account?{' '}
                <Link to={`/signin?redirect=${redirect}`}>Sign-In</Link>
              </div>
            </Form>
          </div>
        </Col>

        <Col md={6} className='mt-3'>
          <img
            src='/images/register.png'
            alt='register'
            className='img-fluid'
          />
        </Col>
      </Row>

      {/* Custom Email Confirmation Modal */}
      <Modal
        show={showEmailConfirmModal}
        onHide={() => setShowEmailConfirmModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please confirm that your email is correct:{' '}
            <span style={{ fontWeight: 'bold', color: 'green' }}>{email}</span>.
          </p>
          <p>
            Incorrect email will affect communication like receipts and
            notifications. Is this email correct?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShowEmailConfirmModal(false)}
          >
            No, Edit Email
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setEmailConfirmed(true); // Confirm the email
              setShowEmailConfirmModal(false); // Close the modal
            }}
          >
            Yes, Confirm Email
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
